@import "liftoff_custom_icons.less";
@import "liftoff_shared_fonts.less";
@import "new_ux_constants.less";

/* ------------------------------------
   Begin Liftoff new UX global styles

   new_ux_global.less
   ------------------------------------ */

body {
  font-family: "Proxima", "Proxima Nova", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: @base-font-size;
  letter-spacing: @letter-spacing;
  color: @darker-gray;
}

/* sidebar width */
:root {
  --sidebar-width: 252px;
  --content-width: 960px;
}

.side-nav-closed {
  --sidebar-width: 72px;
  --content-width: 712px;
}

/* --- structure --- */

/* html */
.scroll-lock {
  overflow: hidden;
}

/* --- everywhere parts --- */

a.link:hover {
  text-decoration: underline;
}

/* Shared with AntD (AntD extends
   these styles) */

.basic-title() {
  color: @darker-gray;
  font-family: "Proxima-semibold", sans-serif;
  font-size: 18px;
  line-height: 1.2em;
}

/* --- form parts --- */

/* override browser presets */
input,
textarea,
select {
  font-family: "Proxima", sans-serif;
  letter-spacing: @letter-spacing;
  margin: 0;
}

::placeholder {
  color: @input-placeholder-color;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected {
  background-color: @button-color-15 !important;
}

select:-internal-autofill-previewed,
select:-internal-autofill-selected {
  background-color: @accent-gold-15 !important;
}

/* super custom graphic checkbox
   these will automatically style normal
   checkbox to our Liftoff
   style. Shared with AntD (AntD extends
   these styles)
*/

label {
  color: @darker-gray;
  position: relative;
}

.disabled label {
  color: @medium-gray;
}

input[type="checkbox"] {
  width: 22px;
  height: 22px;
  position: relative;
  top: 2px;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  height: 22px;
  width: 22px;
  display: inline-block;
  position: relative;
  background: #fff;
  border: 1px solid @light-cool-gray-90;
  border-radius: @border-radius-small;
}

.checkbox-group label {
  margin-bottom: 6px;
  display: inline-block;
}

input[type="checkbox"]:focus::before {
  content: "";
}

input[type="checkbox"]:checked::before {
  background: @button-color;
  border: 1px solid @button-color;
}

input[type="checkbox"]:checked::after {
  &:extend(.ico-checkmark);

  color: #fff;
  font-size: 14px;
  position: absolute !important;
  top: 4px;
  left: 5px;
}

.asterisk-before {
  content: "*";
  color: @error-red;
  font-family: "Proxima-bold", sans-serif;
  font-size: 20px;
  padding-right: 4px;
  display: inline-block;
  position: relative;
  top: 4px;
}

/* disabled */
input[type="checkbox"]:disabled:checked::before,
input[type="checkbox"].disabled:checked::before,
input[type="checkbox"][disabled]:checked::before,
input[type="checkbox"]:disabled::before,
input[type="checkbox"].disabled::before,
input[type="checkbox"][disabled]::before {
  background: @light-cool-gray;
  border-color: @light-cool-gray;
  cursor: default;
}

input[type="checkbox"]:disabled:checked::after,
input[type="checkbox"].disabled:checked::after,
input[type="checkbox"][disabled]:checked::after {
  color: @medium-gray;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  color: @regular-dark-text;
  font-family: "Proxima", sans-serif;
  font-size: @base-font-size;
  line-height: 1em;
  padding: 8px;
  display: inline-block;
  position: relative;
  height: 44px;
  border: 1px solid @border-color;
  border-radius: @border-radius-element;
  cursor: text;

  &:focus,
  &:active {
    padding: 8px 7px;
    border: @focus-border;
    box-shadow: @focus-blur;
    outline: none;
  }
}

input[type="email"],
input[type="password"] {
  &:focus,
  &:active {
    padding: 8px;
  }
}

input[type="text"] {
  padding: 8px 16px;

  &:focus,
  &:active {
    padding: 8px 15px;
  }
}

textarea,
textarea:focus,
textarea:active {
  padding: 12px 14px;
}

input[type="text"].error {
  color: @error-red;
  border-color: transparent;
  background: @error-red-15;

  &:focus,
  &:active {
    border-color: @error-red-15;
    box-shadow: @focus-blur-error;
  }
}

/* buttons
   Shared with AntD (AntD extends
   these styles) */

button,
.button,
a.button {
  color: #fff;
  font-family: "Poppins-medium", sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  text-align: center;
  line-height: 1;
  text-decoration: none;
  padding: 4px 24px 0 24px;
  margin: 16px 0 24px 0;
  height: 44px;
  display: inline-block;
  position: relative;
  background: @button-color;
  border: 2px solid transparent;
  border-radius: 22px;
  outline: none !important;
  box-shadow: none;
  transition: none;
  cursor: pointer;
  background-image: none;
  user-select: none;
  touch-action: manipulation;

  &:hover {
    color: @button-color;
    text-decoration: none;
    background: #fff;
    border-color: @button-color;
  }

  &:focus,
  &:active {
    text-decoration: none;
    outline: none !important;
  }

  &.error {
    background: @error-red;
    border-color: @error-red;

    &:hover {
      color: @error-red;
      background: #fff;
      border-color: @error-red;
    }
  }
}

a.button {
  padding-top: 14px;
}

button.secondary,
.button.secondary {
  color: @button-color;
  background: #fff;
  border-color: @button-color;

  &:hover {
    color: #fff;
    background: @button-color;
  }
}

.button-wrapper-right {
  display: flex;
  justify-content: flex-end;
  position: relative;

  button,
  .button,
  button.cancel,
  .button.cancel {
    margin-bottom: 12px;
  }
}

.button.cancel,
button.cancel {
  color: @button-color;
  font-family: "Poppins-medium", sans-serif;
  padding: 1px 24px 0 24px;
  margin: 16px 0 24px 0;
  height: 44px;
  display: inline-block;
  position: relative;
  top: 0;
  background: transparent;
  border: 2px solid transparent;
  border-radius: 0;
  cursor: pointer;

  &:hover {
    background: transparent;
    border-color: transparent;
  }
}

button:disabled,
button.disabled,
.button.disabled,
button[disabled] {
  color: @medium-gray;
  background: @light-cool-gray-70;
  border-color: @light-cool-gray-70;
  cursor: default;

  &:hover,
  &:active,
  &:focus {
    color: @medium-gray;
    background: @light-cool-gray-70;
    border-color: @light-cool-gray-70;
    cursor: default;
  }
}

.medium-button() {
  font-size: 12px;
  font-family: "Poppins-medium", sans-serif;
  padding: 4px 16px 0 16px;
  height: 36px;
  border-radius: 20px;
}

.small button,
.small .button {
  .medium-button();
}

.small button.cancel,
.small .button.cancel {
  margin-right: 0;
  padding-top: 14px;
  border: 0 solid transparent;
}

button.hover-action {
  color: @button-color;
  font-size: @base-font-size;
  font-family: "Proxima-semibold", sans-serif;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  margin: 0;
  padding: 2px 16px 0 16px;
  height: 32px;
  background: #fff;
  border-color: transparent;
  border-radius: 16px;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background: @button-color;
    border-color: transparent;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active,
  &:disabled:focus {
    color: @medium-gray;
    border-color: @light-cool-gray;
    background: @light-cool-gray;
    cursor: default;
  }
}

button.hover-action.hover-delete:not(:disabled) {
  color: #fff;
  background: @error-red;

  &:hover,
  &:active,
  &focus {
    background: @error-red-80;
  }
}

/* use this to consistenly style
   the input label of any input element
*/

.ui-label {
  color: @medium-gray;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  line-height: 18px;
  position: relative;
  cursor: default;
}

.options-title.ui-label {
  color: @medium-gray;
  font-size: @base-font-size;
  line-height: 22px;
  padding: 16px 32px 10px 16px;
}

/* dropdown */

.options-heading {
  color: @darker-gray;
  font-family: "Proxima-semibold", sans-serif;
  padding: 14px 32px 10px 16px;
  border-bottom: 1px solid @light-cool-gray-90;
}

/* Status indicators */

.status-indicator {
  font-family: "Proxima-bold", sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  line-height: 1em;
  padding-left: 28px;
  position: relative;

  &.state {
    &.paused {
      color: @accent-gold;
    }

    &.paused::before {
      &:extend(.ico-pause);

      font-size: 38px;
      top: -13px;
      left: -4px;
      position: absolute !important;
    }

    &.running {
      color: @accent-green-80;
    }

    &.running::before {
      &:extend(.ico-checkmark-round);

      font-size: 46px;
      top: -17px;
      left: -8px;
      position: absolute !important;
    }

    &.scheduled {
      color: @accent-green-80;
    }

    &.scheduled::before {
      &:extend(.ico-calendar);

      font-size: 28px;
      top: -9px;
      left: -4px;
      position: absolute !important;
    }
  }
}

/* cards */

.ui-cards-wrapper {
  margin-bottom: @card-spacer;
  display: flex;
  justify-content: space-between;
}

.ui-card {
  display: inline-block;
  min-height: 88px;
  position: relative;

  &.single {
    width: 100%;
  }

  &.double {
    width: 472px;
  }

  &.triple {
    width: 309px;
  }

  thead th {
    color: @lv-link-color;
    background: @button-color-15;
  }
}

.ui-card .card-body {
  padding: 24px;
  background: #fff;
  border-radius: @border-radius-element;
}

.card-header {
  padding: 14px 8px 0 8px;
  position: relative;
  line-height: 1em;
  height: 52px;
  background: transparent;

  .ui-title {
    line-height: 1em;
    display: inline-block;

    .subtitle {
      font-size: 16px;
    }

    .icomoon.info {
      font-size: 22px;
      margin-left: 4px;
      position: relative;
      top: 3px;
      cursor: pointer;
    }
  }

  .actions {
    float: right;
    position: relative;
    top: -8px;

    div[class^="css-"].Select__control {
      background: #fff;
    }

    .icon-button {
      float: right;
    }
  }

  .description {
    color: @lv-link-color;
    font-size: 16px;
    letter-spacing: 0;
  }

  .radios {
    padding-right: 88px;
    float: left;
  }

  label {
    color: @lv-link-color;
  }

  input[type="checkbox"]::before,
  input[type="radio"]::before {
    border-color: @light-cool-gray-90;

    &:focus {
      outline: none;
    }
  }

  input[type="checkbox"]:checked::after {
    top: -3px;
  }

  input[type="checkbox"]:disabled:checked::before,
  input[type="checkbox"].disabled:checked::before,
  input[type="checkbox"][disabled]:checked::before,
  input[type="checkbox"]:disabled::before,
  input[type="checkbox"].disabled::before,
  input[type="checkbox"][disabled]::before,
  input[type="radio"]:disabled:checked::before,
  input[type="radio"].disabled:checked::before,
  input[type="radio"][disabled]:checked::before,
  input[type="radio"]:disabled::before,
  input[type="radio"].disabled::before,
  input[type="radio"][disabled]::before {
    border-color: @light-cool-gray;
    background: @light-cool-gray;
  }

  input[type="checkbox"]:disabled:checked::after,
  input[type="checkbox"].disabled:checked::after,
  input[type="checkbox"][disabled]:checked::after {
    color: @medium-gray;
  }

  input[type="radio"]:disabled:checked::after,
  input[type="radio"].disabled:checked::after,
  input[type="radio"][disabled]:checked::after {
    background: @light-cool-gray;
  }

  input[type="checkbox"]:disabled:checked::after,
  input[type="checkbox"].disabled:checked::after,
  input[type="checkbox"][disabled]:checked::after,
  input[type="radio"]:disabled:checked::after,
  input[type="radio"].disabled:checked::after,
  input[type="radio"][disabled]:checked::after {
    opacity: 0.7;
  }
}

.ui-title {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.02em;
}

/* charts */

.chart-tooltip {
  color: @medium-gray;
  font-size: @base-font-size;
  padding: 16px 24px;
  background: #fff;
  border-radius: @border-radius-element;
  box-shadow: @box-shadow;
}

.tooltip-header {
  color: @medium-gray;
  padding-bottom: 8px;
}

.chart-tooltip .value {
  color: @medium-gray;
  font-size: 11px;
  text-transform: uppercase;
}

.chart-tooltip .tooltip-data {
  color: @darker-gray;
  padding: 4px 0 0 12px;
}

/* table */

table {
  border-spacing: 0;
}

thead th {
  color: @button-color;
  font-family: "Proxima-semibold", sans-serif;
  vertical-align: top;
  background: @button-color-15;
}

thead th:first-child {
  border-top-left-radius: @border-radius-element;
}

thead th:last-child {
  border-top-right-radius: @border-radius-element;
}

td {
  color: @darker-gray;
}

th:last-child,
td:last-child {
  padding-right: 16px;
}

/* chart table with legend */

.chart-label,
text.chart-label {
  color: @medium-gray;
  font-family: "Proxima-light", sans-serif;
  font-size: @base-font-size;
}

table.chart-legend {
  width: 100%;

  .breakdown {
    text-align: left;
    padding-left: 16px;
    min-width: 150px;
  }

  thead th {
    font-size: @base-font-size;
    text-align: right;
    padding: 14px 4px 13px 4px;
  }

  tbody tr {
    border-bottom: 1px solid @light-cool-gray-90;
  }

  td {
    text-align: right;
    font-family: "Proxima-light", sans-serif;
    padding: 5px 4px;
  }

  th:last-child,
  td:last-child {
    padding-right: 16px;
  }

  .legend-circle {
    margin-right: 8px;
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }
}

.table-info {
  padding: 8px 0;
}

.table-info::after {
  content: "";
  display: table;
  width: 100%;
}

th {
  input[type="checkbox"]::before {
    border-color: @light-cool-gray-90;
  }

  input[type="checkbox"]:disabled:checked::before,
  input[type="checkbox"].disabled:checked::before,
  input[type="checkbox"][disabled]:checked::before,
  input[type="checkbox"]:disabled::before,
  input[type="checkbox"].disabled::before,
  input[type="checkbox"][disabled]::before {
    border-color: @light-cool-gray;
  }
}

.ui-card th input[type="checkbox"]::before {
  border-color: transparent;
}

tr.employee-only td,
td.employee-only {
  color: @employee-only-purple;
}

tr.employee-only td:first-child,
tr td.employee-only:first-child {
  box-shadow: inset 5px 0 0 @employee-only-purple;
}

div.employee-only {
  position: relative;
  background: @employee-only-purple-10;
  border-radius: @border-radius-element;

  &::before {
    &:extend(.ico-rocket);

    color: @employee-only-purple;
    position: absolute !important;
  }

  label,
  .ui-label {
    color: @employee-only-purple;
  }

  .ui-label {
    font-family: "Proxima-semibold", sans-serif;
    display: inline-block;
  }

  input[type="checkbox"]::before,
  input[type="radio"]::before {
    border: 1px solid @employee-only-purple;
  }

  input[type="checkbox"]:checked::before {
    background: @employee-only-purple;
  }

  input[type="radio"]:checked::after {
    background: @employee-only-purple;
  }

  ::selection,
  input[type="checkbox"]:focus::before,
  input[type="checkbox"]:checked:focus::before,
  input[type="radio"]:focus::before {
    box-shadow: 0 0 3px @employee-only-purple;
  }

  /* disabled */
  input[type="checkbox"]:disabled:checked::before,
  input[type="checkbox"].disabled:checked::before,
  input[type="checkbox"][disabled]:checked::before,
  input[type="checkbox"]:disabled::before,
  input[type="checkbox"].disabled::before,
  input[type="checkbox"][disabled]::before,
  input[type="radio"]:disabled:checked::before,
  input[type="radio"].disabled:checked::before,
  input[type="radio"][disabled]:checked::before,
  input[type="radio"]:disabled::before,
  input[type="radio"].disabled::before,
  input[type="radio"][disabled]::before {
    border-color: @light-cool-gray;
  }

  input[type="checkbox"]:disabled:checked::before,
  input[type="checkbox"].disabled:checked::before,
  input[type="checkbox"][disabled]:checked::before {
    background: @light-cool-gray;
  }

  input[type="checkbox"]:disabled:checked::after,
  input[type="checkbox"].disabled:checked::after,
  input[type="checkbox"][disabled]:checked::after {
    color: @light-cool-gray;
  }

  input[type="radio"]:disabled::before,
  input[type="radio"].disabled::before,
  input[type="radio"][disabled]::before {
    background: @light-cool-gray;
  }

  input[type="radio"]:checked:disabled::after,
  input[type="radio"]:checked.disabled::after,
  input[type="radio"][disabled]:checked::after {
    background: @light-cool-gray;
  }

  .button.cancel {
    color: @employee-only-purple;
  }
}

/* messages and notifications */

.panel-height-perfecter {
  text-align: center;
  margin: 0 auto;
  display: table;
  height: calc(@min-content-height * 0.7);

  .message {
    line-height: 1.25em;
    border-color: transparent;
    border-radius: @border-radius-element;
  }
}

.panel-message-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.panel-message {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: @min-content-height;
}

.panel-message .message {
  border-radius: @border-radius-element;
}

.panel-message.standalone {
  text-align: center;
  min-height: calc(100vh - @footer-height);
  background: @background-color;
}

.panel-message a {
  color: @lv-link-color;

  &:hover {
    text-decoration: underline;
  }
}

.message {
  font-size: @copy-bigger;
  padding: 40px 56px;
  position: relative;
  background: #fff;
  border: 1px solid @light-cool-gray-30;
}

.standalone .message {
  min-width: 745px;
  border-radius: @border-radius-element;
}

.close {
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: 0;
}

.close::before {
  top: 14px;
  right: 16px;
}

/* TODO(deborah): Move these when ui-card is moved into Storybook */

.ui-card .sb-page-notification {
  @ui-card-padding: 40px;

  margin: 0;
  width: 100%;
  border-radius: 0;

  &::before {
    display: none;
  }

  &-content {
    justify-content: space-around;
  }

  .copy {
    padding: 0 0 0 32px;
    display: inline-block;
    position: relative;
  }
}

/* start state ui card - move when ui-card is moved into Storybook */
.start-state .ui-card .sb-page-notification {
  margin: 24px -32px 16px -32px;
  width: calc(100% - 48px);
  box-sizing: content-box;
}

/*
  Shared with AntD (AntD extends these styles)
  In Editor, AntD was not correctly applying
  styles and it had to be brute forced with
  specific styles.
*/

.close-icon,
button.close-icon {
  color: @darker-gray;
  margin: 0;
  display: block;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 14px;
  right: 14px;
  background: transparent !important;
  border: 0 solid transparent;
  opacity: 0.5;
  cursor: pointer;
}

.close-icon:hover,
.close-icon:active {
  background: transparent;
  opacity: 1;
}

.close-icon::before {
  &:extend(.ico-closer);

  color: @darker-gray;
  font-size: 20px;
  position: relative;
  top: 16px;
  right: 0;
  cursor: pointer;
}

.close-icon::after {
  content: "";
  display: table;
  width: 100%;
}

.employee-only .close-icon::before {
  color: @employee-only-purple;
}

/* data display */

.metric-title {
  color: @medium-gray;
  font-size: 13px;
  padding-bottom: 8px;
}

.metric-value {
  color: @darker-gray;
  font-family: "Proxima-thin", sans-serif;
  font-size: 32px;
}

/* status indicators */

span.status {
  display: inline-block;
  position: relative;
  height: 40px;
  width: 40px;
}

.status.refresh::before {
  &:extend(.ico-refresh);
}

.status.sync::before {
  &:extend(.ico-sync);
}

.status-refresh-sync-before() {
  color: @primary-blue;
  font-size: 22px;
  top: 9px;
  left: 9px;
  opacity: 0.5;
}

.status.sync::before,
.status.refresh::before {
  .status-refresh-sync-before();
}

.status-spin() {
  animation: spin 1.5s linear infinite;
  -webkit-animation: spin 1.5s linear infinite;
  opacity: 0.5;
}

.status.refresh,
.status.sync,
button.ant-btn-loading::after {
  .status-spin();
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.status-good-bad-before() {
  font-size: 40px;
  position: absolute;
  top: 2px;
  right: 0;
}

.status.good::before,
.status.bad::before,
.status.invalidated::before {
  .status-good-bad-before();
}

.status.good::before {
  &:extend(.ico-checkmark-round);

  color: @accent-green-80;
}

.status.bad::before {
  &:extend(.ico-exclaim);

  color: @error-red;
}

.status.invalidated::before {
  &:extend(.ico-circle-line);

  color: @medium-gray-50;
}

.thumbs-down,
.thumbs-up {
  display: inline-block;
  position: relative;
}

.thumbs-down::before {
  color: @error-red;
}

.thumbs-up::before {
  color: @accent-green;
}

/* start states */

.start-state,
.mini-start-state {
  .card-header {
    padding-top: 20px;
    padding-right: 24px;
    padding-left: 24px;
    height: 56px;
    background: @button-color-20;
    border-top-left-radius: @border-radius-element;
    border-top-right-radius: @border-radius-element;

    .ui-title {
      color: @lv-link-color;
      font-size: 20px;
      line-height: 1em;
      padding-bottom: 4px;
    }
  }

  .card-body {
    border-radius: unset;
    border-bottom-left-radius: @border-radius-element;
    border-bottom-right-radius: @border-radius-element;
  }

  .ui-card img {
    display: inline-block;
    position: absolute;
  }

  a.button {
    font-family: "Proxima-bold", sans-serif;
    display: inline-block;
  }

  strong {
    font-family: "Proxima-bold", sans-serif;
  }
}

.start-state {
  .ui-card {
    &.has-header .card-body {
      padding: 24px 32px 32px 32px;
    }

    li a {
      color: @button-color;
    }
  }

  h2 {
    margin-bottom: 0;
  }

  h4 {
    font-size: 16px;
    font-family: "Proxima-bold", sans-serif;
    margin: 8px 0;
  }

  .super-subtitle {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    letter-spacing: -0.01em;
    padding-bottom: 8px;
  }

  button,
  a.button {
    margin-left: 40px;
    margin-bottom: 0;
  }
}

.start-state ul.start-state-list {
  padding-bottom: 0;
  padding-left: 0;
  width: 80%;
  list-style-type: none;

  li {
    line-height: 20px;
    padding: 8px 40px 0 40px;
    position: relative;

    &::before {
      content: "";
      height: 32px;
      width: 32px;
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 0;
      background: @primary-purple-50;
      border-radius: 50%;
    }

    &::after {
      color: #fff;
      font-family: "Proxima-bold", sans-serif;
      position: absolute;
      top: 8px;
      left: 12px;
    }

    &:first-child::after {
      content: "1";
      left: 13px;
    }

    &:nth-child(2) {
      margin-top: 8px;
      margin-bottom: 16px;
    }

    &:nth-child(2)::after {
      content: "2";
    }

    &:nth-child(3)::after {
      content: "3";
    }
  }
}

.mini-start-state {
  text-align: center;

  .ui-card {
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    width: unset;
  }

  h2 {
    color: @accent-gold;
    font-size: 32px;
    margin-bottom: 20px;
  }

  .copy {
    font-size: 16px;
    margin-bottom: 8px;
  }

  button,
  a.button {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}

// Shown if the user has javascript disabled in their browser
.noscript-message {
  width: 100%;
  margin: 200px auto;
  max-width: 600px;
  font-size: 18px;
  display: flex;
  flex-direction: column;

  span {
    margin: 8px 0;
  }

  img {
    width: 350px;
  }
}

/* TODO(deborah): move this temporary fix to AntD, makes icon clickable */
.ant-calendar-picker-input.ant-calendar-picker-input {
  position: relative;
  z-index: 1;
}
