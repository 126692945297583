/* ------------------------------
   Begin Liftoff new UX constants

   UPDATED FOR NEW LV BRANDING
   2022

   new_ux_constants.less
   ------------------------------ */
/* --- spacing tokens --- */
/* --- LV updated and new colors ---
       Don't add or rename color constants without checking
       that there are not already existing constants
       in old color files (mixins.less, constants.less).
*/
/* dark blue */
/* primary blue */
/* primary blue with transparency */
/* primary purple */
/* primary purple with transparency */
/* red */
/* green */
/* gold */
/* accent orange */
/* darker gray */
/* medium gray */
/* light-cool-gray */
/* gray with transparency */
/* accent purple */
/* other */
/* Vungle's color ordering for reports */
/* friendly names */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* TODO(deborah) - deprecate this */
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #273143;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #555fff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin .liftoff-spinner {
  width: 48px;
  height: 48px;
  background: url("../../../../node_modules/.pnpm/@liftoffio+antd@2.0.20_gu36zpz44jbquqb5pucyrs7kna/node_modules/@liftoffio/antd/lib/spin/spinner-lv-r4b.png");
  background-size: contain;
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #555fff;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-spin {
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
body {
  font-family: "Proxima", "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #273143;
}
.initialize-dashboard {
  position: relative;
}
.initialize-dashboard .liftoff-spinner {
  height: 48px;
  width: 48px;
  background: url("/images/spinner-lv-r4b.png");
  background-size: contain;
}
.initialize-dashboard img {
  position: fixed;
  bottom: -50px;
  left: -280px;
  height: 552px;
  width: 1012px;
  opacity: 0%;
  animation: initialize-img 1s linear 1s 1;
  animation-fill-mode: forwards;
}
.initialize-dashboard .initialize-spinner {
  margin: 80px auto 0 auto;
  padding: 24px 24px 24px 107px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  animation: initialize-spinner 1s ease 1s 1;
  animation-fill-mode: forwards;
}
.initialize-dashboard .initialize-text {
  font-size: 16px;
  padding-left: 16px;
  position: relative;
  opacity: 0%;
  animation: initialize-text 1s ease 1s 1;
  animation-fill-mode: forwards;
}
@keyframes initialize-img {
  from {
    left: -400px;
    opacity: 0%;
  }
  to {
    left: -280px;
    opacity: 100%;
  }
}
@keyframes initialize-text {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
@keyframes initialize-spinner {
  from {
    padding-left: 87px;
    opacity: 0%;
  }
  to {
    padding-left: 0;
    opacity: 100%;
  }
}
