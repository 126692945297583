@import (reference) "../../style/core.less";

.icon.icomoon {
  line-height: 1em;
}

.sb-icon-closer {
  opacity: 0.5;
  color: @darker-gray;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &.darker {
    opacity: 1;
    color: #fff;
  }
  &.darker:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &.closes {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 56px;
    position: absolute;
    top: 0;
    right: 0;
  }

  &.extra-large {
    font-size: 24px;
  }
  &.large {
    font-size: 20px;
  }
  &.medium {
    font-size: 16px;
  }
  &.small {
    font-size: 14px;
  }

  &.sb-delete-x {
    border: 0 solid transparent !important;
    background: transparent;
    opacity: 1;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
      opacity: 0.5;
    }

    &.black {
      color: @darker-gray;
    }

    &.red {
      color: @error-red;
    }

    &.employee-only {
      color: @employee-only-purple;

      &::before {
        content: "";
      }
    }

    &[disabled] {
      color: @medium-gray-65;
      pointer-events: none;
    }
  }
}
