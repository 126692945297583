.liftoff-spinner {
  position: inherit;
  height: 48px;
  width: 48px;
  background: url("./spinner-lv-r4b.png");
  background-size: contain;
}
.sb-spin {
  text-align: center;
  width: 48px;
  height: 48px;
}
.sb-spin-dot {
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.sb-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.sb-spin-dot-spin {
  transform: rotate(45deg);
  animation: spinRotate 1.2s infinite linear;
}
.sb-spin-nested-loading {
  position: relative;
}
.sb-spin-nested-loading > div > .sb-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.sb-spin-nested-loading > div > .sb-spin .sb-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -24px;
}
.sb-spin-nested-loading > div > .sb-spin .sb-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 24px;
}
.sb-spin-nested-loading > div > .sb-spin.spin-show-text .sb-spin-dot {
  margin-top: -34px;
}
.sb-spin-nested-loading > div > .sb-spin-sm .sb-spin-dot {
  margin: -16px;
}
.sb-spin-nested-loading > div > .sb-spin-sm .sb-spin-text {
  padding-top: 16px;
}
.sb-spin-nested-loading > div > .sb-spin-sm.spin-show-text .sb-spin-dot {
  margin-top: -28px;
}
.sb-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.sb-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s;
  content: "";
  pointer-events: none;
}
.sb-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.sb-spin-sm {
  width: 32px;
  height: 32px;
}
.sb-spin.sb-spin-show-text .sb-spin-text {
  display: flex;
  justify-content: center;
}
.sb-spin-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
@keyframes spinRotate {
  to {
    transform: rotate(405deg);
  }
}
