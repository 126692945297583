@import (reference) "../../style/core";

.icon-tooltip {
  padding: 2px 4px;
  display: inline-flex;
  align-items: center;
  position: relative;

  .icon {
    font-size: 20px;
    line-height: inherit;
    cursor: pointer;
  }

  &.tooltip-warn .icon,
  &.tooltip-error .icon {
    font-size: 22px;
  }

  & > *:nth-child(2) {
    margin-left: 0.2em;
  }
  &.has-tooltip {
    &.tooltip-warn {
      color: @accent-gold-150;
      background-color: @accent-gold-10;
    }
    &.tooltip-error {
      color: @error-red;
      background-color: @error-red-15;
    }
  }
}

/* tooltip appears outside of content */
.ant-tooltip.sb-icon-tooltip .ant-tooltip-arrow {
  display: none;
}
