.icon-tooltip {
  padding: 2px 4px;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.icon-tooltip .icon {
  font-size: 20px;
  line-height: inherit;
  cursor: pointer;
}
.icon-tooltip.tooltip-warn .icon,
.icon-tooltip.tooltip-error .icon {
  font-size: 22px;
}
.icon-tooltip > *:nth-child(2) {
  margin-left: 0.2em;
}
.icon-tooltip.has-tooltip.tooltip-warn {
  color: #ffb600;
  background-color: #fffae6;
}
.icon-tooltip.has-tooltip.tooltip-error {
  color: #f91974;
  background-color: #fee8f1;
}
/* tooltip appears outside of content */
.ant-tooltip.sb-icon-tooltip .ant-tooltip-arrow {
  display: none;
}
