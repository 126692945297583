@import (reference) "../../style/core.less";

.error-boundary-default {
  height: 100%;
  width: 100%;
  color: @error-red;
  background: @error-red-15;
  border-color: @error-red-15;
  display: flex;
  justify-content: center;

  & > * {
    align-self: center;
  }

  span {
    margin-left: 8px;
  }

  .icon {
    font-size: 24px;
    top: -2px;
  }
}
