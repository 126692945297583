@import "@liftoff/frontendlib/src/css/new_ux_constants.less";
@import "@liftoffio/antd/lib/spin/style/index.less";

body {
  font-family: "Proxima", "Proxima Nova", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: @base-font-size;
  letter-spacing: @letter-spacing;
  color: @darker-gray;
}

.initialize-dashboard {
  position: relative;

  .liftoff-spinner {
    height: 48px;
    width: 48px;
    background: url("/images/spinner-lv-r4b.png");
    background-size: contain;
  }

  img {
    position: fixed;
    bottom: -50px;
    left: -280px;
    height: 552px;
    width: 1012px;
    opacity: 0%;
    animation: initialize-img 1s linear 1s 1;
    animation-fill-mode: forwards;
  }

  .initialize-spinner {
    margin: 80px auto 0 auto;
    padding: 24px 24px 24px 107px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: @border-radius-element;
    animation: initialize-spinner 1s ease 1s 1;
    animation-fill-mode: forwards;
  }

  .initialize-text {
    font-size: @copy-bigger;
    padding-left: 16px;
    position: relative;
    opacity: 0%;
    animation: initialize-text 1s ease 1s 1;
    animation-fill-mode: forwards;
  }

  @keyframes initialize-img {
    from {
      left: -400px;
      opacity: 0%;
    }
    to {
      left: -280px;
      opacity: 100%;
    }
  }

  @keyframes initialize-text {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }

  @keyframes initialize-spinner {
    from {
      padding-left: 87px;
      opacity: 0%;
    }
    to {
      padding-left: 0;
      opacity: 100%;
    }
  }
}
