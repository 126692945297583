/* ------------------------------------
   Begin @liftoff/components
   ------------------------------------ */
/* ------------------------------------
   data_title.less

   lightweight tooltip similar to an
   image "alt tag"

   <div data-title="Here is a hint">
     Something that needs a hint
   </div>
------------------------------------ */
[data-title] {
  position: relative;
}
[data-title]:hover::after {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 1s;
}
[data-title]::after {
  content: attr(data-title);
  color: #273143;
  font-family: "Proxima", sans-serif;
  font-size: 12px;
  text-transform: none;
  line-height: 1.25em;
  padding: 4px 6px;
  position: absolute;
  top: -16px;
  left: 100%;
  background: #fff;
  border: 1px solid #ebeff7;
  border-radius: 2px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  z-index: 3;
  transition: none;
}
/* TODO(deborah) - add constants to our new constants styles
   when AntD branch is merged, also remove the imported overrides */
a {
  color: #555fff;
}
.indicator-dot {
  padding-left: 24px;
  position: relative;
}
.indicator-dot::after {
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 3px;
  left: 0;
  background: #00bf56;
  border-radius: 50%;
}
.basic-title {
  color: #273143;
  font-family: "Proxima-semibold", sans-serif;
  font-size: 18px;
  line-height: 1.2em;
}
.card-date {
  color: #95a2bc;
  font-size: 13px;
  letter-spacing: 0.03em;
  line-height: 1em;
  text-transform: uppercase;
  text-align: right;
  margin: 16px 0 0 2px;
}
.status-spin {
  animation: spin 1.5s linear infinite;
  -webkit-animation: spin 1.5s linear infinite;
  opacity: 0.5;
}
/* when modal is showing, prevent scrolling the screen behind it */
.scroll-lock {
  overflow: hidden;
}
