@import (reference) "liftoff_shared_fonts.less";
@import (reference) "new_ux_constants.less";

/* ------------------------------------
   data_title.less

   lightweight tooltip similar to an
   image "alt tag"

   <div data-title="Here is a hint">
     Something that needs a hint
   </div>
------------------------------------ */

[data-title] {
  position: relative;
}

[data-title]:hover::after {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 1s;
}

[data-title]::after {
  content: attr(data-title);
  color: @darker-gray;
  font-family: "Proxima", sans-serif;
  font-size: 12px;
  text-transform: none;
  line-height: 1.25em;
  padding: 4px 6px;
  position: absolute;
  top: -16px;
  left: 100%;
  background: #fff;
  border: 1px solid @border-color;
  border-radius: 2px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  z-index: 3;
  transition: none;
}
