.error-boundary-default {
  height: 100%;
  width: 100%;
  color: #f91974;
  background: #fee8f1;
  border-color: #fee8f1;
  display: flex;
  justify-content: center;
}
.error-boundary-default > * {
  align-self: center;
}
.error-boundary-default span {
  margin-left: 8px;
}
.error-boundary-default .icon {
  font-size: 24px;
  top: -2px;
}
