@import (reference) "../../style/core.less";

@small-spin-size: 32px;
@default-spin-size: 48px;

.liftoff-spinner {
  position: inherit;
  height: @default-spin-size;
  width: @default-spin-size;
  background: url("./spinner-lv-r4b.png");
  background-size: contain;
}

.sb-spin {
  text-align: center;
  width: @default-spin-size;
  height: @default-spin-size;

  &-dot {
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: middle;

    &-item {
      position: absolute;
      display: block;
      width: 9px;
      height: 9px;
      border-radius: 100%;
      transform: scale(0.75);
      transform-origin: 50% 50%;
      opacity: 0.3;
      animation: antSpinMove 1s infinite linear alternate;
    }

    &-spin {
      transform: rotate(45deg);
      animation: spinRotate 1.2s infinite linear;
    }
  }

  &-nested-loading {
    position: relative;

    > div > .sb-spin {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      display: block;
      width: 100%;
      height: 100%;
      max-height: 400px;

      .sb-spin-dot {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -@default-spin-size / 2;
      }

      .sb-spin-text {
        position: absolute;
        top: 50%;
        width: 100%;
        padding-top: (@default-spin-size - @base-font-size) / 2 + 7px;
      }
      &.spin-show-text .sb-spin-dot {
        margin-top: -@default-spin-size / 2 - 10px;
      }
    }

    > div > .sb-spin-sm {
      .sb-spin-dot {
        margin: -@small-spin-size / 2;
      }
      .sb-spin-text {
        padding-top: (@default-spin-size - @base-font-size) / 2 - 1px;
      }
      &.spin-show-text .sb-spin-dot {
        margin-top: -@small-spin-size / 2 - 12px;
      }
    }
  }

  &-container {
    position: relative;
    transition: opacity 0.3s;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      display: ~"none \9";
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.3s;
      content: "";
      pointer-events: none;
    }
  }

  &-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
  }

  &-sm {
    width: @small-spin-size;
    height: @small-spin-size;
  }

  &&-show-text &-text {
    display: flex;
    justify-content: center;
  }

  &-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

@keyframes spinRotate {
  to {
    transform: rotate(405deg);
  }
}
