.icon.icomoon {
  line-height: 1em;
}
.sb-icon-closer {
  opacity: 0.5;
  color: #273143;
}
.sb-icon-closer:hover {
  cursor: pointer;
  opacity: 1;
}
.sb-icon-closer.darker {
  opacity: 1;
  color: #fff;
}
.sb-icon-closer.darker:hover {
  cursor: pointer;
  opacity: 0.7;
}
.sb-icon-closer.closes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  position: absolute;
  top: 0;
  right: 0;
}
.sb-icon-closer.extra-large {
  font-size: 24px;
}
.sb-icon-closer.large {
  font-size: 20px;
}
.sb-icon-closer.medium {
  font-size: 16px;
}
.sb-icon-closer.small {
  font-size: 14px;
}
.sb-icon-closer.sb-delete-x {
  border: 0 solid transparent !important;
  background: transparent;
  opacity: 1;
}
.sb-icon-closer.sb-delete-x:hover,
.sb-icon-closer.sb-delete-x:focus,
.sb-icon-closer.sb-delete-x:active {
  background: transparent;
  opacity: 0.5;
}
.sb-icon-closer.sb-delete-x.black {
  color: #273143;
}
.sb-icon-closer.sb-delete-x.red {
  color: #f91974;
}
.sb-icon-closer.sb-delete-x.employee-only {
  color: #9f1cba;
}
.sb-icon-closer.sb-delete-x.employee-only::before {
  content: "";
}
.sb-icon-closer.sb-delete-x[disabled] {
  color: #b6c0d3;
  pointer-events: none;
}
