/* ------------------------------------
   Begin @liftoff/components
   ------------------------------------ */
@import (reference) "@liftoff/frontendlib/src/css/new_ux_global.less";
@import "@liftoff/frontendlib/src/css/data_title.less";

/* TODO(deborah) - add constants to our new constants styles
   when AntD branch is merged, also remove the imported overrides */

a {
  color: @lv-link-color;
}

.indicator-dot {
  padding-left: 24px;
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    height: 10px;
    width: 10px;
    position: absolute;
    top: 3px;
    left: 0;
    background: @accent-green;
    border-radius: 50%;
  }
}

.sb-notification-colors() {
  &.warning {
    color: @accent-gold-150;
    background: @accent-gold-10;
  }

  &.error {
    color: @error-red;
    background: @error-red-15;
  }

  &.success {
    color: @accent-green;
    background: @accent-green-15;
  }

  &.info {
    color: @primary-blue-150;
    background: @primary-blue-15;
  }

  &.employee-only {
    color: @employee-only-purple;
    background: @employee-only-purple-10;
  }
}

.basic-title {
  color: @regular-dark-text;
  font-family: "Proxima-semibold", sans-serif;
  font-size: 18px;
  line-height: 1.2em;
}

.card-date {
  color: @regular-light-text;
  font-size: 13px;
  letter-spacing: 0.03em;
  line-height: 1em;
  text-transform: uppercase;
  text-align: right;
  margin: 16px 0 0 2px;
}

.status-spin {
  .status-spin();
}

/* when modal is showing, prevent scrolling the screen behind it */
.scroll-lock {
  overflow: hidden;
}
